export default [
    // not found (404 page)
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    // authentication
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/verify/index',
        name: 'verify-index',
        component: () => import('@/views/pages/verify/index.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () =>
            import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'auth-reset-password',
        component: () =>
            import('@/views/pages/authentication/ResetPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    // not authorized
    {
        path: '/not-authorized',
        name: 'misc-not-authorized',
        component: () =>
            import('@/views/pages/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    // maintenance mode
    {
        path: '/under-maintenance',
        name: 'misc-under-maintenance',
        component: () =>
            import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },
    // posts
    {
        path: '/posts',
        name: 'posts-index',
        component: () => import('@/views/pages/posts/List.vue'),
    },
    {
        path: '/posts/:id',
        name: 'posts-show',
        component: () => import('@/views/pages/posts/Detail.vue'),
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/views/pages/contact-us/ContactUs.vue'),
    },
    {
        path: '/bug-report',
        name: 'bug-report',
        component: () => import('@/views/pages/bug-report/BugReport.vue'),
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/views/pages/about-us/AboutUs.vue'),
    },
]
